import React from "react"

import { Link } from "gatsby"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <div className="page-wrapper lg:w-11/12">
      <SEO title="404: Not found" />
      <div className="content-wrapper h-screen flex flex-col justify-center">
        <div className="self-end  mx-auto">
          <h1 className="text-mortino-900 text-4xl font-sans">
            PÁGINA NO ENCONTRADA
          </h1>
          <p className="text-mortino-copy my-4 text-lg tracking-wider">
            Solicitaste una página que no existe todavía. Lo sentimos
          </p>
          <Link to="/" className="text-purple-900 underline text-lg">
            Volver al Inicio
          </Link>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
